<template>
  <div>
    <div>
      <van-card
          :num="num"
          :price="teacher_id == 100 ? itemInfo.price + ' / 天' : itemInfo.price"
          :desc="itemInfo.desc"
          :title="itemInfo.name"
          :thumb="itemInfo.img_url"
          class="border-b"
          @click-thumb="imgClick()"
      >
        <template #tags>
          <van-tag
              v-show="itemInfo.show_inventories == 1"
              plain
              :type="itemInfo.inventories_num > 0 ? 'primary' : 'danger'"
          >{{
              itemInfo.inventories_num > 0 ? "库存数量 : " + itemInfo.inventories_num : "已售罄"
            }}
          </van-tag
          >
        </template>
        <template #footer>
          <van-button icon="minus" size="normal" @click="changeCount(-1)"></van-button>
          <van-button
              icon="plus"
              size="normal"
              type="danger"
              @click="changeCount(1)"
          >
          </van-button>
        </template>
      </van-card>
    </div>
    <div>
      <div class="p-3 mt-2 space-y-4">
        <div class="text-xs flex items-center gap-2" style="color: #f7863f" v-if="itemInfo.tip">
          <van-icon name="warning-o"/>
          <span
              style="overflow: hidden;white-space: pre-wrap;word-break: break-all;letter-spacing: 0.5px;font-size: 14px !important;">{{ itemInfo.tip }}</span>
        </div>
        <van-radio-group v-model="payType" direction="horizontal">
          <van-radio v-for="item in payTypeList" :key="item.name" :name="item.name">{{item.value }}
          </van-radio>
        </van-radio-group>
        <div class="pricebox">
          合计:
          <span class="redd1">¥</span>
          <span class="redd" v-if="itemInfo.price">{{
              actionOperation(itemInfo.price, num)
            }}</span>
        </div>
      </div>
      <div class="my-2 px-3">
        <van-button type="danger" block round @click="onSave">立即支付</van-button>
      </div>
    </div>
    <van-dialog v-model="showQR" title="请用微信扫码" show-cancel-button @confirm="qrokClick()">
      <div class="mx-auto flex justify-center">
        <vue-qr :text="code_url" qid="testid"></vue-qr>
      </div>
    </van-dialog>
    <van-dialog v-model="showResult" title="请确认信息" show-cancel-button @confirm="payClick()">
      <div>
        <van-cell-group>
          <van-cell title="购买的商品:" :value="itemInfo.name" value-class="valueclass"/>
          <van-cell title="个数" :value="num" value-class="num"/>
          <van-cell
              title="总价"
              v-if="itemInfo.price"
              :value="actionOperation(itemInfo.price, num)"
              value-class="price"
          />
        </van-cell-group>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import {areaList} from "@vant/area-data";
import {Dialog, ImagePreview, RadioGroup} from "vant";
import VueQr from "vue-qr";

function createAddressInfo() {
  return {
    id: Date.now(),
    "name": "",
    "tel": "",
    "teacher_id": 1,
    "teacher_name": ""
  }
}

export default {
  name: "no-input-teacher",
  components: {
    VueQr, [RadioGroup.name]: RadioGroup
  },
  data() {
    return {
      num: 1,
      showQR: false,
      code_url: "出错!",
      areaList,
      payType: "wx",
      payTypeList: [],
      showResult: false,
      addressInfoList: [],
      item_id: "",
      itemInfo: {},
      notes: "",
      user_id: "",
      teacher_id: new URLSearchParams(window.location.search).get("teacher_id") || 0,
      teacher_list: []
    };
  },
  created() {
    this.initPayType();
  },
  watch: {
    num() {
      let n = Number(this.num) || 0;
      if (this.addressInfoList.length < n) {
        this.addressInfoList.push(createAddressInfo());
      } else if (this.addressInfoList.length > n) {
        this.addressInfoList.splice(this.addressInfoList.length - 1, 1);
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.item_id = this.$route.query.id;
      this.user_id = this.$ls.get("user_id");
      this.getItemInfo();
    } else {
      Dialog.alert({
        title: "参数错误 id=" + this.item_id,
      }).then(() => {
        // on close
        window.location.href = "/";
      });
    }
    this.getTeacherList();
    this.addressInfoList = [createAddressInfo()];
  },
  methods: {
    getTeacherInfo(id) {
      return this.teacher_list.filter(e => {
        return e.id == id;
      })[0]
    },
    changeCount(diff) {
      let newValue = this.num + diff;
      if (newValue > 8) {
        newValue = 8;
        this.$toast({message: "最多购买8个", type: "fail"});
        return;
      }
      if (newValue < 1) {
        newValue = 1;
        this.$toast({message: "最少购买1个", type: "fail"});
        return;
      }
      this.num = newValue;
    },
    async getTeacherList() {
      const result = await this.$axios.get("/teacher/getTeacherList");
      this.teacher_list = result.data;
      this.teacher_list = this.teacher_list.filter(e => {
        return e.id !== 10 && e.id !== 17;
      })
      let t = {
        id: 1,
        name: "任意讲师均可"
      }
      this.teacher_list.push(t);
    },

    qrokClick() {
      window.location.href = `/user?teacher_id=${this.teacher_id}`;
    },

    initPayType() {
      let payTypeList = [];
      if (this._isMobile()) {
        if (this.isWechat()) {
          payTypeList.push({name: "JSAPI", value: "手机微信"});
        } else {
          payTypeList.push({name: "H5", value: "微信支付"});
          payTypeList.push({name: "ali_wap", value: "手机支付宝"});
        }
      } else {
        payTypeList.push({name: "NATIVE", value: "微信扫码"});
      }

      this.payTypeList = payTypeList;
      if (payTypeList.length > 0) {
        this.payType = payTypeList[0].name;
      }
    },

    isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },

    async onSave() {
      this.showResult = true;
    },

    onDelete() {
      this.$ls.remove("address");
      this.$toast("成功");
    },

    async payClick() {
      let open_id = this.$ls.get("user_openid");
      let res = await this.$axios.post(`/item/addOrder?teacher_id=${this.teacher_id}`, {
        item_user_id: this.user_id,
        item_id: this.item_id,
        count: this.num,
        pay_channel: this.payType,
        open_id: open_id,
      });
      if (res.code == 10001 || res.code == 10000) {
        this.$toast(res.msg);
        let data = res.data;
        if (data.status == 2) {
          //免费的,直接跳转到我的订单列表页
          this.$router.push({
            name: "/user",
            query: {
              teacher_id: this.teacher_id,
            },
          });
        } else {
          if (this.payType == "JSAPI") {
            //这里是微信支付的参数
            this.$toast("调起微信支付中");

            this.onBridgeReady(res.data);
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady, false);
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
              }
            } else {
              this.onBridgeReady();
            }
          } else if (this.payType == "ali_wap") {
            const div = document.createElement("div");
            div.innerHTML = data; //res.data是返回的表单
            document.body.appendChild(div);
            document.forms.alipaysubmit.submit();
          } else if (this.payType == "H5") {
            let teacher_id = new URLSearchParams(window.location.search).get("teacher_id") || null;
            let url = "https://mall.zhangcaijing.net/user";
            if(teacher_id) {
              url += `?teacher_id=${teacher_id}`;
            }
            window.location.href = data.mweb_url + `&redirect_url=${encodeURIComponent(url)}`;
          } else if (this.payType == "NATIVE") {
            this.code_url = data.code_url;
            this.showQR = true;
          } else {
            this.$toast.fail("暂不支持");
          }
        }
      } else {
        Dialog.alert({
          title: res.msg,
        }).then(() => {
        });
      }
    },

    async getItemInfo() {
      let res = await this.$axios.get("/item/getItemInfo", {
        params: {
          item_id: this.item_id,
        },
      });
      this.itemInfo = res.data;
      if (this.itemInfo.inventories_num == 0) {
        Dialog.alert({
          message: "当前商品已售罄!",
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },

    imgClick() {
      ImagePreview({
        images: [this.itemInfo.img_url],
        closeable: true,
      });
    },

    _isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    onBridgeReady(data) {
      var that = this;

      WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: data.appId, //公众号名称，由商户传入
            timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: data.nonceStr, //随机串
            package: data.package,
            signType: data.signType, //微信签名方式：
            paySign: data.paySign, //微信签名
          },
          function (res) {
            that.$toast(res.err_msg);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              that.$dialog
                  .alert({
                    title: "提示",
                    message: "支付成功,点击确定回到我的订单",
                    theme: "round-button",
                  })
                  .then(() => {
                    window.location.href =
                        "https://mall.zhangcaijing.net/user?teacher_id=" + that.teacher_id;
                  });
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              that.$toast.fail("已取消");
            }
          }
      );
    },

    actionOperation(price = 0, num = 0) {
      let m = 0,
          s1 = price.toString(),
          s2 = num.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {
      }
      try {
        m += s2.split(".")[1].length;
      } catch (e) {
      }
      return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
    },
  },
};
</script>
<style>
.van-address-edit__buttons {
  padding: 0 0 !important;
}

.redd {
  color: #f35a5c;
  font-size: 1.25rem;
}

.redd1 {
  color: #f35a5c;
  font-size: 0.63rem;
}

.pricebox {
  font-size: 1.25rem;
}

.van-card__num {
  color: #000 !important;
  font-size: 1rem;
}

.van-card__price {
  color: #000 !important;
  font-size: 1rem !important;
}

.zongjia {
  color: #f35a5c !important;
  font-size: 1.13rem;
}

.valueclass {
  color: #000 !important;
  font-size: 1.13rem;
  flex: 2 !important;
}

.van-card__title {
  font-size: 1rem;
  line-height: 1.25rem !important;
  max-height: fit-content;
}

.van-cell__value.notes {
  text-align: center !important;
}

.van-dialog__content {
  max-height: 50vh;
  overflow-y: auto;
}

.odd-bg {
  @apply bg-white;
}

.even-bg {
  @apply bg-gray-100;
}

.result-list .van-cell {
  background-color: transparent;
}

.choose-teacher {
  flex-direction: column;
}

.choose-teacher .van-field__label {
  width: 100%;
  @apply mb-2;
}
</style>
